import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { grabStatus } from "../api/payment";

const PaymentStatus = ({ match }) => {
  const { user } = useSelector((state) => ({ ...state }));
  const [values, setValues] = useState({
    amount: "",
    error: "",
  });

  const { amount, error } = values;

  useEffect(() => {
    getPaymentStatus(match.params.paymentId);
  }, []);
  const getPaymentStatus = async (paymentId) => {
    await grabStatus(paymentId).then((response) => {
      if (response.error) {
        setValues({ ...values, error: response.error, amount: "" });
      } else {
        setValues({ ...values, error: "", amount: response.amount });
        console.log(response);
        // if (response.captured === true) {
        //   const res = createRazorpayCapture(user.token, formValues);
        //   console.log(res);
        // }
      }
    });
  };

  console.log(match);

  return (
    <div className="container">
      {error && <h1 style={{ color: "red" }}>{error}</h1>}
      {amount > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <h1 style={{ color: "green" }}>
            Your order of rs {amount / 100} is successfull
          </h1>
        </div>
      )}
      {!error && !amount && <h1>Loading...</h1>}
    </div>
  );
};

export default PaymentStatus;
