import axios from "axios";

export const getPricings = async () => {
  return await axios.get(`${process.env.REACT_APP_BACKEND_API}/pricings`);
};

export const removePricing = async (pricingId, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_API}/pricing/${pricingId}`,
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const createPricing = async (pricing, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_API}/pricing`,
    pricing,
    {
      headers: {
        authtoken,
      },
    }
  );
};
