import React, { useState, useEffect } from "react";
import { getAllIndianSubscribers } from "../../../api/user";
import { adminSendMailToMember } from "../../../api/admin";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const DraftMessageForm = ({
  handleSend,
  draftMessage,
  setDraftMessage,
  sendingMessage,
  draftSubject,
  setDraftSubject,
}) => {
  return (
    <>
      {sendingMessage ? (
        <h3>Sending Message</h3>
      ) : (
        <form onSubmit={handleSend}>
          <div style={{ marginBottom: "20px" }} className="md-3">
            <input
              type="text"
              className="form-control"
              value={draftSubject}
              onChange={(e) => setDraftSubject(e.target.value)}
              placeholder="Subject"
              required
            />
          </div>
          <div className="mb-3">
            <textarea
              className="form-control"
              style={{ resize: "none", width: "100%" }}
              value={draftMessage}
              onChange={(e) => setDraftMessage(e.target.value)}
              rows="5"
              placeholder="Message..."
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      )}
    </>
  );
};
const IndianMembers = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [members, setMembers] = useState([]);
  const [loading, setloading] = useState(true);
  const [messageMember, setMessageMember] = useState({});
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [draftVisible, setDraftVisible] = useState(false);
  const [draftMembers, setDraftMembers] = useState([]);
  const [draftMessage, setDraftMessage] = useState("");
  const [draftSubject, setDraftSubject] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);
  const [visible, setVisible] = useState(false);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    loadIndianMembers();
  }, []);

  const loadIndianMembers = async (id) => {
    await getAllIndianSubscribers(id)
      .then((res) => {
        setMembers(res.data);
        setloading(false);
      })

      .catch((err) => {
        // history.push("/");
        console.error("Some error in fetching the data");
      });
  };
  const handleSendMessage = (member) => {
    setMessageMember(member);
    setShowMessageBox(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    adminSendMailToMember(user.token, message, messageMember.email)
      .then((res) => {
        toast.success(res.data);
        setShowMessageBox(false);
        setMessageMember({});
        setMessage("");
        setSending(false);
      })
      .catch((err) => {
        toast.error(
          err.response
            ? err.response.data
            : "Some error occured please try later"
        );
        console.log(err);
        setSending(false);
      });
  };

  const handleSelect = (value, member, index) => {
    const membersToUpdate = draftMembers;
    if (value) {
      const i = membersToUpdate.findIndex((item) => item == member);
      if (!i >= 0) {
        membersToUpdate.push(member);
        setDraftMembers(membersToUpdate);
      }
    } else {
      const resultingmembers = membersToUpdate.filter(
        (each) => each !== member
      );
      setDraftMembers(resultingmembers);
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    try {
      setSendingMessage(true);
      const checkboxes = document.getElementsByClassName("select-check");
      if (!draftMembers.length) {
        setSendingMessage(false);
        return toast.error("First please select some members");
      }
      await adminSendMailToMember(
        user.token,
        draftMessage,
        draftMembers,
        draftSubject
      );
      for (let i = 0; i < checkboxes.length; i++)
        if (checkboxes[i].type == "checkbox") checkboxes[i].checked = false;
      setSendingMessage(false);
      setDraftVisible(false);
      setDraftMembers([]);
      setDraftMessage("");
      setDraftSubject("");
      toast.success("Message sent successfully");
    } catch (error) {
      toast.error("Some error occured please try later");
      console.log(error);
      setSendingMessage(false);
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between">
        <h4>Indian Members</h4>
        <div>
          <button
            className="btn cust-btn"
            onClick={() => setDraftVisible(true)}
          >
            Draft Message
          </button>
          <Modal
            title="Draft Messaging"
            centered
            visible={draftVisible}
            footer={null}
            onCancel={() => setDraftVisible(false)}
            width={1000}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              className="selected-members-for-draft"
            >
              {draftMembers.length
                ? draftMembers.map((each, i) => {
                    return <p key={i}>{each}</p>;
                  })
                : ""}
            </div>
            <DraftMessageForm
              handleSend={handleSend}
              draftMessage={draftMessage}
              setDraftMessage={setDraftMessage}
              sendingMessage={sendingMessage}
              draftSubject={draftSubject}
              setDraftSubject={setDraftSubject}
            />
          </Modal>
        </div>
      </div>

      <div className="mt-4 table-responsive">
        {loading ? (
          <h4>Loading....</h4>
        ) : (
          <table
            className="table table-responsive"
            style={{ display: "inline-table" }}
          >
            <thead>
              <tr>
                <th>Select</th>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Country</th>
              </tr>
            </thead>
            <tbody>
              {members.map((each, i) => {
                return (
                  <>
                    <tr key={i}>
                      <td>
                        <input
                          className="select-check"
                          type="checkbox"
                          onChange={(e) => {
                            handleSelect(e.target.checked, each.email, i);
                          }}
                        />
                      </td>
                      <td>{each._id}</td>
                      <td>{each.name}</td>
                      <td>{each.email}</td>
                      <td>{each.country}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default IndianMembers;
