import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createPricing,
  removePricing,
  getPricings,
} from "../../../api/pricing";
import { DeleteOutlined } from "@ant-design/icons";

const Pricing = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [before_discount_price, setBefore_discount_price] = useState("");
  const [discount, setDiscount] = useState("");
  const [currency, setCurrency] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState("");
  const [pricings, setPricings] = useState([]);

  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadAllPricings();
  }, []);

  const loadAllPricings = () => {
    getPricings().then((res) => {
      setPricings(res.data);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    createPricing(
      {
        title,
        description,
        before_discount_price,
        discount,
        country,
        currency,
      },
      user.token
    )
      .then((res) => {
        setLoading(false);
        setTitle("");
        setBefore_discount_price("");
        setCountry("");
        setDiscount("");
        setCurrency("");
        toast.success(`${res.data.title} is created`);
        loadAllPricings();
      })
      .catch((error) => {
        console.log("CREATE Pricing ERROR", error);
      });
  };

  const handleDeletePricing = (id) => {
    // console.log(id);
    if (window.confirm("Delete?")) {
      setLoading(true);
      removePricing(id, user.token).then((res) => {
        setLoading(false);
        loadAllPricings();
        toast.success(`${res.data.title} Deleted`);
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          {loading ? (
            <h4 className="text-danger">Loading...</h4>
          ) : (
            <h4>Pricing</h4>
          )}

          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label className="text-muted">Title</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  required
                />
              </div>

              <div className="form-group col-md-4">
                <label className="text-muted">Before Discount Price</label>
                <input
                  type="number"
                  className="form-control"
                  value={before_discount_price}
                  onChange={(e) => setBefore_discount_price(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-4">
                <label className="text-muted"> Discount in % </label>
                <input
                  type="number"
                  className="form-control"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label className="text-muted">Currency</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setCurrency(e.target.value)}
                  value={currency}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label className="text-muted">Country</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                  required
                />
              </div>
              <div className="form-group col-md-12">
                <label className="text-muted">Description</label>
                <input
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  className="form-control"
                ></input>
              </div>
            </div>

            <button className="btn btn-outline-primary">Save</button>
          </form>

          <br />

          <h4>{pricings.length} Pricings</h4>

          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Amount Before Discount</th>
                <th scope="col">Discount(%)</th>
                <th scope="col">Amount</th>
                <th scope="col">Currency</th>
                <th scope="col">Country</th>
              </tr>
            </thead>

            <tbody>
              {pricings.map((c) => (
                <tr key={c._id}>
                  <td>{c.title}</td>
                  <td>{c.before_discount_price}</td>
                  <td>{c.discount}</td>
                  <td>{c.amount}</td>
                  <td>{c.currency}</td>
                  <td>{c.country}</td>
                  <td>
                    <DeleteOutlined
                      className="text-danger pointer"
                      onClick={() => handleDeletePricing(c._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
