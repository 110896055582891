import axios from "axios";

export const getPayment = (priceID) => {
  return fetch(`${process.env.REACT_APP_BACKEND_API}/razorpay/create/${priceID}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const grabStatus = (paymentId) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_API}/razorpay/payments/${paymentId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const createPaymentStatus = async (authtoken, id) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_API}/paymentStatus/new`,
    {
      orderedBy: id,
    },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const getAllPaymentStatus = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_API}/paymentStatus/all`
  );
};

export const getpaymentStatus = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_API}/paymentStatus/${id}`
  );
};
